.hero {
  position: relative;
  background-color: var(--fenhuma-white);
  padding: 8rem 0 10rem;
  color: var(--fenhuma-black);
  font-size: 1.6rem;
  line-height: 2.2rem;
  background-image: url("../img/hcp-hero-background.svg");
  background-repeat: no-repeat;
  background-position: center top;

  @include mobile(){
    background-image: none;
  }



  .inner-container:first-of-type{
    min-height: 520px;

    @include mobile(){
      min-height: auto;
    }
  }



  span.welcome{
    color: var(--fenhuma-pink);
    font-weight: 700;
    text-transform: uppercase;
    padding-bottom: 1.5rem;
    display: inline-block;
    font-size: 12px;
    letter-spacing: 1px;
  }

  .inner-container {
    display: flex;
    gap: 6rem;

    @include mobile {
      flex-direction: column;
    }
  }
  .left {
    flex: 1 0;


  }

  .right {
    flex: 0.7 0;
    @include mobile(){
      margin-top: 3rem;
    }
  }

  h1 {
    font-size: 4rem;
    line-height: 4.5rem;
    font-weight: 800;
    color: var(--fenhuma-blue);

    small {
      display: block;
      font-size: 4rem;
      font-weight: 400;
    }

    @include mobile(){
      br{
        display: none;
      }
    }

  }

  a{
    color: var(--fenhuma-pink);
    text-decoration: underline;
  }

  p {
    font-weight: 400;
    margin-top: 1.5rem;
    //margin-bottom: 3.5rem;
    opacity: 0.8;

    @include mobile(){
      margin-bottom: 0;
    }

  }

  ul {
    list-style-type: none;
    padding: 0;
    font-weight: 700;
    display: flex;
    flex-direction: column;
    gap: 2rem;

    li {
      display: flex;
      gap: 2.4rem;
      align-items: center;

      .icon {
        width: 4.8rem;
        height: 4.8rem;
        background-color: var(--fenhuma-pink);
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 0;

        img {
          max-width: 50%;
        }
      }

      span {
        flex: 1;
        font-weight: 400;
        opacity: 0.8;
      }
    }
  }

  .image-wrapper {
    position: absolute;
    display: flex;
    top: 0;
    right: 0;

    img {
      object-fit: cover;

      &:first-of-type {
        border-bottom-left-radius: 20px;
        border-top-left-radius: 20px;
      }
    }

    @include mobile(){
      position: static;
    }
  }

  .hero-boxes{
    display: flex;
    flex-direction: row;
    gap: 2rem;
    margin-top: 5rem;

    @include mobile(){
      flex-direction: column;
    }

    & > div{
      flex-basis: 33%;
      box-shadow: 8px 8px 32px rgb(0 72 135 / 4%);
      border-radius: 10px;
      text-align: center;
      padding: 20px;
      color: var(--fenhuma-black);
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .icon{
        margin: auto;
      }

      p{
        opacity: 0.8;
        margin-bottom: 2.5rem;
        margin-top: 2.5rem;
      }

    }
  }

  .hero-home-circles {
    position: absolute;
    top: 0;
    left: calc(25% + 8rem);
    max-height: 60%;

    @include mobile {
      display: none;
    }
  }

  .mobile-circles {
    position: absolute;
    top: 0;
    right: 0;
  }

  &--initiating {
    p {
      margin-bottom: 0;

      a {
        text-decoration: underline;
      }
    }
    svg {
      position: absolute;
      top: 0;
      left: 50%;
      height: 100%;
      transform: translateX(-50%);

      @include mobile(){
        display: none;
      }
    }

    .hero-image {
      position: absolute;
      border-radius: 0.8rem 0 0 0.8rem;
      box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.3);
      object-fit: cover;
    }
  }

  @include mobile {
    padding: 2.4rem 0;

    .inner-container {
      gap: 0;
      //margin-bottom: 28.4rem;
    }

    h1 {
      font-size: 3.2rem;
      line-height: 4rem;
    }

    ul {
      font-size: 1.4rem;
      line-height: 1.8rem;
    }

    .image-wrapper {
      //top: calc(100% - 28.4rem);
      height: auto;
      //display: none ;

      img {
        object-position: top;

        &:first-of-type {
          border-top-left-radius: 0.5rem;
        }
      }
    }

    &--initiating {
      margin-bottom: 0;
      z-index: 2;
      .hero-image {
        bottom: -2rem;
      }
    }
  }
}
