body #root .hcp-popup-header {
  width: 100%;
  background-color: var(--bunov-white);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-image: url("../img/hcp-confirmation.svg");
  background-position: bottom -130px right -50px;
  background-repeat: no-repeat;


  @include mobile(){
    background-position: bottom -400px right -50px;
  }


  .logo {
/*    position: absolute;
    top: 0;
    left: 0;*/

    background-color: var(--bunov-white);
    justify-content: center;
    align-items: center;

    width: calc(var(--container-width) + calc(var(--container-padding) * 2));
    padding: 0 var(--container-padding);
    margin: 0 auto;
    max-width: 100%;
    padding-top: 40px;

    img {
      max-width: 25rem;
    }
  }

  .inner-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 10rem;
    margin-bottom: 10rem;

    .popup-circles {
      position: absolute;
      top: 0;
      left: 0;
      width: 572px;
      max-width: 100%;
    }
  }

  h1 {
    text-align: center;
    font-size: 6.4rem;
    line-height: 5.8rem;
    font-weight: 800;
    color: var(--fenhuma-blue);
    font-family: "Inter", sans-serif;

    small {
      display: block;
      font-size: 3.2rem;
      font-weight: 400;
      margin-top: 0.6rem;
    }
  }

  .button-container {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    margin-top: 3rem;
    max-width: 100%;

    button {
      font-size: 1.6rem;
      width: 52.5rem;
      max-width: 100%;
    }
  }

  .disclaimer {
    font-size: 1.4rem;
    color: var(--fenhuma-black);
    margin-top: 1rem;
  }

  .link-container {
    margin-top: 3rem;

    .disclaimer {
      font-size: 1.6rem;
      font-weight: 700;
    }

    ul {
      list-style: none;
      display: flex;
      justify-content: center;
      gap: 2rem;
      margin-top: 1rem;

      li {
        font-size: 1.6rem;
        color: var(--fenhuma-black);
        opacity: 0.8;

        a {
          text-decoration: underline;
        }
      }
    }
  }

  @include tablet-portrait {
    min-height: 77rem;
    padding-top: 4rem;

    .inner-container{
      margin-top: 5rem;
    }

    .logo {
      padding: 1.5rem 3rem;
      img {
        max-width: 18.6rem;
        margin: auto;
        display: block;
      }
    }

    h1 {
      font-size: 3.2rem;
      line-height: 4rem;
    }

    .disclaimer {
      font-size: 1.2rem;
      text-align: center;
    }

    .link-container {
      .disclaimer {
        font-size: 1.4rem;
      }

      ul {
        margin-top: 2rem;
        padding: 0;
        gap: 1rem;
        flex-direction: column;
        text-align: center;
      }
    }
  }

  @include mobile {
    h1 {
      br {
        display: none;
      }
    }
  }
}

section.section--grey.hcp-popup a {
  color: var(--fenhuma-pink);
}
