.heading {
  &--large {

    background-color: #fff;

    .container {
      display: flex;
      gap: 7rem;
      padding: 6rem 0;
      align-items: center;

      .left,
      .right {
        flex: 1;
      }

      .left {
        position: relative;
        img {
          position: relative;
          //box-shadow: 0px 4px 40px rgba(24, 34, 16, 0.24);
          z-index: 2;
        }
      }

      .right {
        position: relative;
        h1 {
          margin-bottom: 2rem;
          font-size: 40px;
          line-height: 40px;
        }

        p {
          //padding-right: 12rem;
        }

        a:not(.btn){
          color: var(--fenhuma-blue);
          text-decoration: underline;
        }
      }
    }

    @include tablet-portrait {
      .container {
        flex-direction: column;
        gap: 6rem;
        padding-top: 2rem;
        padding-left: 1.5rem;
        padding-right: 1.5rem;

        .right {
          p {
            padding-right: 0;
          }
        }
      }
    }
  }

  &--small {
    background-color: var(--bunov-white);
    padding: 8.5rem 0 8.5rem 0;

    h1 {
      margin: 0.8rem 0 3rem 0;
    }

    p {
      margin-bottom: 0.1rem;
    }

    a{
      color: var(--fenhuma-blue);
      text-decoration: underline;
    }
  }

  &.home-heading{
    .container {

      .right {

        h1 {

          font-size: 72px;
          line-height: 72px;

          @include mobile(){
            font-size: 45px;
            line-height: 45px;
          }
        }

      }
    }
  }

  &.taking-fenhuma-hero{
    p.right-dose{
      font-weight: 800;
      font-size: 18px;
      margin-bottom: 2rem;
    }
  }
}
