.header__notice {
  width: 100%;
  height: 4rem;
  background-color: var(--fenhuma-black);
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 202;
  letter-spacing: 1px;

  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    p {
      margin: 0;
      color: #FCFCFC;
      text-transform: uppercase;
      font-weight: 600;
      text-transform: uppercase;
      font-size: 1.2rem;
      line-height: 1;

      a {
        color: #FCFCFC;
        margin-left: 0.5rem;
        text-decoration: underline;
      }
    }

    ul {
      color: #fcfcfc;
      display: flex;
      padding: 0;
      list-style-type: none;
      font-size: 1.2rem;
      font-weight: 600;

      li {
        text-transform: uppercase;
        opacity: 1;

        &:after {
          content: "|";
          color: #fcfcfc;
          margin: 0 1rem;
          opacity: 0.6;
        }

        &:last-of-type {
          &:after {
            content: "";
          }
        }

        a {
          text-decoration: underline;
          color: var(--fenhuma-white);
        }
      }
    }
  }

  @include tablet-portrait {
    height: auto;
    padding: 2rem 0;
    letter-spacing: 0.1rem;
    top: 0;

    .container {
      flex-direction: column;
      text-align: center;
      padding: 0 15px;

      p {
        line-height: 1.6rem;
        font-size: 1rem;
        a {
          display: block;
          margin-top: 0.3rem;
        }

        &:after {
          content: "";
          display: block;
          width: 100%;
          height: 1px;
          background-color: #a1a59d;
          margin: 1.5rem 0;
        }
      }

      ul {
        font-size: 1rem;
        justify-content: center;
        align-items: center;
        gap: 1.5rem;

        li {
          &:after {
            display: none;
          }
        }
      }
    }
  }
  @include mobile {
    top: -72px;
  }
}

header {
  position: relative;
  z-index: 101;

  .header__main {
    width: 100%;
    height: 12.4rem;
    background-color: var(--bunov-white);

    .container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      align-items: center;
      height: 100%;
    }

    &__logo {
      img {
        max-width: 25rem;
      }
    }

    .burger-menu {
      display: none;
    }

    nav {
      ul {
        margin: 0;
        padding: 0;
        display: flex;
        list-style: none;
        display: flex;
        gap: 5.6rem;
        justify-content: flex-end;

        @include tablet-portrait {
          flex-direction: column;
        }

        li {
          a {
            font-size: 1.6rem;
            text-decoration: none;
            color: #182210;

            &.active {
              font-weight: 700;
            }
          }

          &.link-btn {
            opacity: 1;
            a {
              color: var(--fenhuma-white);

              &:hover{
                color: var(--fenhuma-pink);
              }
            }
          }
        }
      }
    }

    @include tablet-portrait {
      height: 8rem;

      &__logo {
        img {
          max-width: 14.6rem;
        }
      }

      nav {
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        z-index: 100;
        background-color: var(--bunov-white);
        height: 0;
        overflow: hidden;
        transition: height 0.3s ease;

        ul {
          width: 100%;
          gap: 0;
          li {
            width: 100%;
            padding: 0 2.4rem;
            a {
              display: block;
              // padding: 2rem 0;
              text-align: center;

              &:after {
                content: "";
                width: 100%;
                height: 1px;
                background-color: #90948b;
                display: block;
                margin: 4rem 0;
                opacity: 0.1;
              }
            }

            &:first-of-type {
              a {
                padding-top: 2rem;
              }
            }

            &:last-of-type {
              a {
                margin-bottom: 4.8rem;
                &:after {
                  display: none;
                }
              }
            }
          }
        }
      }

      .burger-menu {
        display: block;
        width: 4rem;
        height: 2.4rem;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-end;
        transition: align-items 0.3s ease;

        span {
          width: 100%;
          height: 0.4rem;
          background-color: #182210;

          &:nth-of-type(2) {
            width: 3.2rem;
          }

          &:nth-of-type(3) {
            width: 2.4rem;
          }
        }

        &.open {
          align-items: flex-start;

          & ~ nav {
            height: 42.5rem;
          }
        }
      }
    }
  }

  .indicator {
    position: absolute;
    bottom: 0.5rem;
    left: 0;
    width: 100%;

    &__ball {
      position: absolute;
      width: 1rem;
      height: 1rem;
      background-color: var(--fenhuma-pink);
      border-radius: 50%;
      transition: all 0.3s ease;
      bottom: 3.1rem;

      &--hide {
        opacity: 0;
      }
    }

    @include tablet-portrait {
      display: none;
    }
  }
}
