@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Noto+Serif:ital,wght@0,400;0,700;1,400;1,700&display=swap");

@import "mixins";

:root {
  --container-width: 1315px;
  --inner-container-width: 1040px;
  --resource-container-width: 910px;
  --container-padding: 15px;
  --bunov-black: #182210;
  --bunov-orange: hsl(29, 71%, 51%);
  --bunov-orange-hover: #d2682c;
  --bunov-green: #3e5529;
  --bunov-white: #fcfcfc;
  --bunov-grey: #f3f3f3;
  --bunov-dark-grey: #8b997f;
  --bunov-beige: #e8e8e4;
  --bunov-purple: #5545a3;

  --fenhuma-white: #fcfcfc;
  --fenhuma-black: #1f1300;
  --fenhuma-blue: #004887;
  --fenhuma-pink: #d30c7b;
  --fenhuma-grey:  #F2F2EF;

  @include mobile {
    --container-padding: 24px;
  }

  @include tablet-portrait {
    --container-padding: 24px;
  }
}

*,
*::before,
::after {
  box-sizing: border-box;
  margin-block: 0;
}

html {
  font-size: 10px;
}

html,
body {
  margin: 0;
  padding: 0;
  font-size: 10px;
  font-family: "Inter", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f3f3f3;
  min-height: 100vh;
  color: var(--bunov-black);
}

body {
  font-size: 1.6rem;
  line-height: 2.2rem;
  color: var(--bunov-black);
}

a {
  color: inherit;
  text-decoration: none;

  &:hover {
    opacity: 0.9;
  }
}

img {
  max-width: 100%;

  &.dropshadow {
    box-shadow: 0px 4px 40px rgba(24, 34, 16, 0.24);
  }
}

#root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  main {
    flex: 1;
    scroll-behavior: smooth;
  }
}

sup {
  line-height: 0;
}

svg {
  pointer-events: none;
}

h1,
h2,
h3 {
  color: var(--fenhuma-blue);
}

h3 {
  font-size: 2.4rem;
  font-weight: 800;
}

ul,ol {
  li {
    opacity: 0.8;
  }
}

em {
  text-transform: uppercase;
  font-size: 1.2rem;
  font-weight: 700;
  color: var(--fenhuma-pink);
  font-style: normal;
}

.container {
  width: calc(var(--container-width) + calc(var(--container-padding) * 2));
  padding: 0 var(--container-padding);
  margin: 0 auto;
  max-width: 100%;
}

.inner-container {
  &--large {
    --inner-container-width: 1090px;
  }

  width: calc(
    var(--inner-container-width) + calc(var(--container-padding) * 2)
  );
  padding: 0 var(--container-padding);
  margin: 0 auto;
  max-width: 100%;
}

.resource-container {
  width: calc(
    var(--resource-container-width) + calc(var(--container-padding) * 2)
  );

  margin: 0 auto;
  max-width: 100%;

  @include mobile(){
    padding: 0 var(--container-padding);
  }

}

.btn {
  padding: 1.6rem 2.4rem;
  border: 1px solid;
  border-radius: 6rem;
  font-weight: 600;
  font-size: 1.6rem;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  text-decoration: none;



  &.inline-block{
    display: inline-block;
  }


  &--primary {
    background-color: var(--fenhuma-pink);
    border-color: var(--fenhuma-pink);
    color: var(--fenhuma-white);
    text-decoration: none;

    &:hover {
      background-color: var(--fenhuma-white);
      border-color: var(--fenhuma-pink);
      color: var(--fenhuma-pink);
    }

    &.on-blue:hover{
      background-color: var(--fenhuma-blue);
      border-color: var(--fenhuma-white);
      color: var(--fenhuma-white);
    }
  }

  &--outline {
    background-color: transparent;
    color: var(--fenhuma-pink);
    border-color: var(--fenhuma-pink);

    &:hover {
      background-color: var(--fenhuma-pink);
      border-color: var(--fenhuma-pink);
      color: var(--fenhuma-white);
    }
  }

  &--secondary {
    background-color: var(--bunov-purple);
    color: var(--fenhuma-white);
    border-color: var(--bunov-purple);

    &:hover {
      background-color: #4d3e93;
    }
  }

  &--patient{
    background-color: var(--fenhuma-blue);
    color: var(--fenhuma-white);
    border-radius: 5px;
    display: inline-block;
    text-decoration: none;

    &:hover{
      background-color: #063D6C ;
    }

  }

  &--icon {
    display: flex;
    align-items: center;
    gap: 1rem;
  }

  &[disabled] {
    background-color: #b8bbb5;
    color: rgba(252, 252, 252, 0.5);
    border-color: rgba(252, 252, 252, 0.5);
    cursor: not-allowed;
  }
}

.icon {
  width: 4.8rem;
  height: 4.8rem;
  border-radius: 50%;
  background-color: var(--fenhuma-pink);
  display: flex;
  justify-content: center;
  align-items: center;
}

.hide-desktop {
  display: none;
}

@include tablet-portrait {
  .hide-desktop {
    display: initial;
  }

  .hide-mobile {
    display: none;
  }
}

body {
  &.site-hcp {
    @import "hcp";
  }

  &.site-patient {
    @import "patient";
  }
}

@import "components/sections";
@import "components/resources";
@import "components/footer";
@import "components/hcp-popup";
@import "components/external-popup";
@import "components/cookie-popup";
@import "components/hcp/cta";
@import "components/table";
@import "components/grid";
@import "components/utils";

.grecaptcha-badge {
  visibility: hidden;
}
