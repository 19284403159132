.section {
  position: relative;
  padding: 8rem 0;

  p.full-opacity{
    opacity: 1;
  }

  p a.external-link{
    color: var(--fenhuma-blue);
  }

  &.section--grey{
    background-color: var(--fenhuma-grey);
  }

  &.direction {
    .container {
      display: grid;
      gap: 2rem;
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: 1fr;
      padding: 0;

      @include tablet-portrait {
        grid-template-columns: 1fr;
        padding: 0 1.5rem;
      }

      .direction-box {
        --backgroundColor: #fff;
        --fontColor: var(--fenhuma-black);

        position: relative;
        background-color: var(--backgroundColor);
        padding: 5rem 5.4rem;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        overflow: hidden;
        color: var(--fontColor);
        transition: all 0.3s ease;
        border-radius: 4px;

        @include mobile(){
          padding: 2rem;
        }

        svg{
          position: absolute;
          bottom: 45px;

          @include mobile(){
            position: static;
            margin-top: 20px;
          }



          path{
            fill: var(--fenhuma-black);
          }
        }

        a {
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
        }

        .content {
          margin: 4rem 0 5rem 0;
          @include mobile(){
            margin: 2rem;
          }
        }


        p {
          margin-top: 1.6rem;
          font-size: 1.4rem;
          line-height: 2rem;
          margin-bottom: 0;
        }

        &:hover {
          --backgroundColor: var(--fenhuma-blue);
          --borderColor: var(--fenhuma-white);
          --fontColor: var(--fenhuma-white);
          transform: translateY(-10px);

          h3{
            color: var(--fontColor);
          }

          svg{
            path{
              fill: var(--fenhuma-white);
            }
          }
        }
      }
    }
  }

  &.alongside-other-meds, &.sleep-related, &.food-and-driving, &.pregnancy-section{
    h2{
      margin-top: 1.6rem;
    }
  }

  &.sleep-related{
    .container{
      .right{
        display: flex;
        flex-direction: column;
        justify-content: center;

        img{
          width: 48px;
        }
      }
    }
  }


  &.download-summary{
    a.btn{
      img{
        vertical-align: middle;
        padding-left: 5px;
      }
    }
  }

  &.alongside-other-meds{


    .grid.grid-2-cols{
      margin-top: 5rem;
      column-gap: 5rem;
    }
  }

  &.how-to-apply {
    .container {
      align-items: center;
    }
    .right {
      .btn {
        margin-top: 1.5rem;
        display: inline-block;
      }
    }

    .left {
      img {
        max-height: 42rem;
      }
    }

    .apply-container {
      display: flex;
      flex-direction: column;

      .box {
        width: 45%;
        height: 180px;
        border: 1px solid #8b997f;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 2rem 3rem;
        background-color: #fff;

        @media (max-width: 1050px) {
          height: 150px;
        }

        @include mobile {
          height: 110px;
        }

        img {
          max-width: 100%;
          height: 100%;
          display: block;
        }
      }

      .top {
        display: flex;

        .arrow {
          margin: 0 1.5rem;
          max-width: 3.2rem;
        }
      }

      .centre {
        display: flex;
        justify-content: flex-end;
        margin-right: 25%;

        .arrow {
          margin: 1.5rem 0;
        }
      }

      .bottom {
        display: flex;
        .arrow {
          margin: 0 1.5rem;
          max-width: 3.2rem;
        }
      }
    }
  }

  &.speak-to-doctor,
  &.section-how-do {
    .container {
      align-items: center;
    }
  }

  &#doctor{
    p.more-space, ul li:last-of-type{
      margin-bottom: 3rem;
    }

    a img{
      vertical-align: middle;
      padding-left: 5px;
      width: 16px;

      @include mobile(){
          width: 17px;
      }

    }

  }

  &.speak-to-doctor{

    .container{
      column-gap: 10rem;
    }

    .left{
      img{
        margin-bottom: 2rem;
      }
    }


  }

  &.reported-side-effects {
    .left {
      align-items: flex-start;
    }
    p {
      &.with-marker {
        font-weight: bold;
        margin-bottom: 0;

        & + p {
          margin-top: 0;
          margin-left: 2.1rem;
        }

        &:before {
          content: "";
          display: inline-block;
          width: 1rem;
          height: 1rem;
          background-image: url("../img/circle-marker.svg");
          background-repeat: no-repeat;
          margin-right: 1rem;
        }
      }
    }
  }

  &.bunov-and-driving {
    .left {
      align-items: flex-start;
    }

    h3 {
      display: flex;
      gap: 2.4rem;
      margin-bottom: 3.2rem;
      font-size: 2.4rem;
      line-height: 3.2rem;
      font-weight: 700;

      img {
        width: 8rem;
        height: 8rem;
      }
    }
    .white-backdrop {
      p {
        margin-bottom: 1rem;
      }

      ul {
        margin-bottom: 0;

        li {
          &:last-of-type {
            margin-bottom: 0;
          }
        }
      }
    }
  }


  &.talk-to-doctor{
    .container{
      .right{
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
    }
  }

  &.pill-strengths{

    .grid{
      grid-column-gap: 4rem;
      grid-row-gap: 4rem;
    }

    @include mobile(){
      .grid.grid-3-cols{
        grid-template-columns: repeat(2, 1fr);
      }
    }

    .right{
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .pill-wrapper{

      text-align: center;
      color: var(--fenhuma-pink);
      font-size: 12px;
      font-weight: 700;
      text-transform: uppercase;

      .pill{
        padding: 1rem;
        border-radius: 6px;
        margin-bottom: 1rem;

        &.yellow{
          background-color: #F9B428;
        }

        &.blue{
          background-color: #22B1E0;
        }

        &.green{
          background-color: #45AC33;
        }

        &.orange{
          background-color: #EB672C;
        }

        &.pink{
          background-color: #D3145D;
        }

        &.red{
          background-color: #E1251C;
        }

        img{
          display: block;
          margin: auto;
          width: 75%;
        }
      }
    }


  }

  &.found-right-dose{
    .right{
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }

  &.taking-the-medication{
    .box{
      background-color: var(--fenhuma-white);
      text-align: center;
      padding: 50px;
      margin-bottom: 2rem;

      img{
        margin-bottom: 1.5rem;
        filter: none;
      }
    }

    .container{
      .left{
        display: flex;
        flex-direction: column;
        justify-content: center;
      }

      .right img{
        filter: drop-shadow(4px 4px 32px rgba(0, 72, 135, 0.06));
      }
    }

  }

  &.taken-wrong-dose, &.learn-more-about-pain{
    .btn{
      margin-top: 2rem;
    }
  }

  &.what-if-i-take-more{
    .box{
      background-color: var(--fenhuma-white);
      text-align: center;
      padding: 40px;
      margin-bottom: 2rem;
      box-shadow: 8px 8px 32px rgba(0, 72, 135, 0.06);
      border-radius: 4px;

      width: 90%;

      img{
        margin-bottom: 1.6rem;
      }

      @include mobile(){
        width: 100%;
      }

    }
  }


  &.se-reported-se{
    .left{
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }


  &.what-is-breakthrough-pain{
    .right{

      p{
        text-align: center;
        font-weight: 800;
      }

      .box{
        background-color: var(--fenhuma-white);
        padding: 40px;
        box-shadow: 8px 8px 32px rgba(0, 72, 135, 0.06);
        border-radius: 16px;

        @include mobile(){
          padding: 20px;
        }

        ul{
          list-style-type: none;
          margin: 0;
          padding: 0;
          opacity: 1;
          margin-top: 30px;

          li{
            opacity: 1;
            display: flex;
            flex-direction: row;
            justify-content: center;
            width: 100%;
            margin-bottom: 40px;

            &:last-of-type{
              margin-bottom: 0;
            }

            span{
              opacity: 0.8;
              flex-grow: 1;
              display: flex;
              align-items: center;
              padding-left: 2rem;
            }

            img{
              display: inline-block;
            }
          }
        }
      }

    }

    .left{
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

  }

  &--references {
    padding: 4rem 0;
    background-image: url("/src/img/fenhuma-bar.png");
    background-size: 100% 8px;
    background-position: top;
    background-repeat: no-repeat;


    a.external-link{
      color: var(--fenhuma-blue);
    }

    .inner-container{
      width: calc(var(--container-width) + calc(var(--container-padding) * 2));
      padding: 0 var(--container-padding);
    }

  }

  &--to-remember {
    h2 {
      width: 100%;
      text-align: left;
      margin-bottom: 6.4rem;
      margin-top: 3rem;
    }

    .remember-grid-container {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(10rem, 31.3rem));
      justify-content: center;
      gap: 2rem;
      row-gap: 4.8rem;

      @include tablet-portrait {
        justify-content: space-between;
      }

      .item {
        display: flex;
        flex-direction: column;
        text-align: left;

        .purple-icon {
          width: 8rem;
          height: 8rem;
          background-color: var(--bunov-purple);
          display: flex;
          justify-content: center;
          align-items: center;
          margin-bottom: 2rem;
        }

        p {
          &:nth-of-type(2) {
            margin-top: 1rem;
          }
        }
      }
    }
  }

  &--steps {
    .container {
      margin-bottom: 5.2rem;

      .guide-three {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border: 1px solid #8b997f;
        width: 100%;
        padding: 2rem 0;
        background-color: #fff;

        .img-wrapper {
          width: 36rem;
          max-width: 100%;
          img {
            width: 100%;
          }
        }
        .divider {
          width: 1px;
          height: 200px;
          background-color: #8b997f;
        }

        @include tablet-portrait {
          flex-direction: column;
          padding: 2rem;

          .divider {
            height: 1px;
            width: 90%;
          }
        }
      }

      .step-container {
        display: flex;
        flex-direction: column;
        gap: 2.4rem;
        width: 1094px;
        max-width: 100%;
        margin: 0 auto;
        margin-top: 10rem;

        .step {
          display: flex;
          justify-content: space-between;
          align-items: center;
          text-align: left;

          &__copy {
            width: 600px;
            max-width: 100%;
            padding: 0 1.5rem;

            h4 {
              margin-bottom: 2rem;
            }

            p {
              margin-bottom: 1.5rem;
            }
          }

          .img-wrapper {
            width: 424px;
            max-width: 100%;
            border: 1px solid #8b997f;
            padding: 4.6rem;
            background-color: #fff;
          }

          &--reverse {
            flex-direction: row-reverse;
          }
        }

        @include tablet-portrait {
          .step {
            flex-direction: column;

            .img-wrapper {
              margin-bottom: 2rem;
            }
          }
        }
      }
    }

    .inner-container {
      width: 84.5rem;
      max-width: 100%;
      text-align: left;

      p {
        margin-bottom: 2rem;
      }
    }
  }

  &--guide {
    .beige-backdrop {
      ul {
        padding: 0;
        list-style-type: none;

        li {
          font-weight: bold;
          display: flex;
          gap: 1.5rem;
          &:before {
            display: flex;
            content: "";
            width: 1rem;
            height: 1rem;
            background-image: url("../img/circle-marker.svg");
            background-repeat: no-repeat;
            margin-top: 0.6rem;
          }

          span {
            flex: 1;
          }
        }
      }
    }
  }

  &--resources{

    &.section--beige{
      background-color: #F2F2EF;
    }

  }

  .beige-backdrop,
  .white-backdrop {
    background-color: var(--fenhuma-grey);
    padding: 4.8rem;

    h3 {
      margin-bottom: 2rem;
    }

    ul {
      li {
        margin-bottom: 1rem;
      }
    }

    p {
      margin-bottom: 0;
    }

    @include tablet-portrait {
      br {
        display: none;
      }
    }
  }

  .white-backdrop {
    background-color: var(--bunov-white);

    &.with-dropshadow {
      box-shadow: 0px 4px 40px rgba(24, 34, 16, 0.04);
    }
  }
}

.pointer {
/*  &:before {
    content: "";
    position: absolute;
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
    background-color: #8a987e;
  }*/

  &:after {
    content: "";
    position: absolute;
    width: 10px;
    height: 8px;
    background-color: #8a987e;
    background-image: url("/src/img/patient-bar-right@2x.png");
    background-size: 100% 8px;
  }

  &--top {
    &:before {
      top: -0.5rem;
    }

    &:after {
      top: 0rem;
    }
  }



  &--right-50 {
    &:before {
      left: 50%;
      transform: translateX(-50%);
    }
    &:after {
      left: 25%;
      transform: translateX(calc(50% + 0.7rem));
      width: calc(50% - 0.5rem);

      @include mobile(){
        background-image: url("/src/img/fenhuma-bar.png");
        width: 100%;
        left: 0;
        transform: none;
      }
    }
  }

  &--left-50 {
    &:after {
      left: 0%;
      top: 0px;
      width: calc(50% - 0.5rem);
      background-image: url("/src/img/patient-bar-left@2x.png");

      @include mobile(){
        background-image: url("/src/img/fenhuma-bar.png");
        width: 100%;
        left: 0;
        transform: none;
      }
    }
  }

  &--left-33 {
    &:before {
      left: 33%;
      transform: translateX(-33%);
    }
    &:after {
      left: 0;
      width: 33%;
    }
  }

  &--left-25 {
    &:before {
      left: 25%;
      transform: translateX(-25%);
    }
    &:after {
      left: 0;
      width: 25%;
    }
  }


  &--pull-up{
    &:after {
      top: -8px;
    }
  }

  &--full{
    background-image: url("/src/img/fenhuma-bar.png");
  }
}

main {
  &.page--how {
    .heading--small {
      padding-bottom: 0;

      h1 {
        margin-bottom: 0;
      }

      & ~ section:not(.section--references) {
        padding-top: 3rem;
      }
    }
  }
}
