.heading {
  background-color: var(--bunov-white);
  padding: 8rem 0;

  h1 {
    font-size: 4.8rem;
    font-weight: 700;
  }

  p {
    font-size: 1.6rem;

    &.bold {
      font-weight: 700;
    }

    &:first-of-type {
      margin-top: 3rem;
      margin-bottom: 2rem;
    }
  }

  @include mobile {
    padding: 6.4rem 0;

    h1 {
      font-size: 3.2rem;
      line-height: 3.4rem;
    }
  }
}
