@import "components/hcp/header";
@import "components/hcp/hero";
@import "components/hcp/heading";
@import "components/hcp/contact";





.section-available-doses{
  .doses-grid{
    color: var(--fenhuma-pink);
    font-size: 12px;
    font-weight: 700;
    margin-bottom: 20px;
    margin-top: 20px;

    img{
      margin-bottom: 10px;
      padding: 10px;
      border: 1px solid #F3F3F3;
      border-radius: 6px;
    }
  }

  .left{
    justify-content: center;
  }
}

.dose-titration{
  img{
    margin-bottom: 40px;
    margin-top: 20px;
  }
}

.body-opacity {
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s ease;
}

&.menu-open {
  .body-opacity {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: #e8e8e4;
    opacity: 0.6;
    z-index: 100;
  }
}