main.contact {
  background-color: var(--bunov-white);
}

.section--contact {
  form {
    display: flex;
    flex-direction: column;

    .form-top {
      display: flex;
      width: 100%;
      flex-direction: column;

      .form-left {
        flex: 1;
      }

      .form-right {
        flex: 1;
      }
    }
  }

  .form-row {
    display: flex;
    justify-content: space-between;
    width: 100%;
    gap: 2rem;
  }

  .field {
    flex: 1;
    display: flex;
    flex-direction: column;
    text-align: left;
    margin-bottom: 1.5rem;
    position: relative;

    input,
    textarea {
      width: 100%;
      height: 5.6rem;
      background: var(--fenhuma-white);
      border: 1px solid rgba(24, 34, 16, 0.08);
      border-radius: 4px;
      font-size: 1.6rem;
      padding-left: 1.5rem;
      color: var(--fenhuma-black);
      resize: vertical;

      &:active,
      &:focus {
        outline: none;
        border-color: var(--fenhuma-pink);
      }
      &.error {
        border-color: var(--fenhuma-pink);

        & ~ img {
          display: block;
        }
      }
    }
    textarea {
      height: 20rem;
      font-family: "Inter", sans-serif;
      padding-top: 1rem;
    }

    label {
      font-size: 1.6rem;
      padding-left: 1.5rem;
      color: var(--bunov-black);
      margin-bottom: 1rem;
    }

    img {
      display: none;
      position: absolute;
      right: 17px;
      top: 47%;
    }

    .error-message {
      color: #e93811;
      font-size: 1.2rem;
      padding-left: 1.5rem;
      margin-top: 0.5rem;

      &.hide {
        opacity: 0;
      }
    }
  }
  .submit-wrapper {
    display: flex;
    justify-content: flex-end;

    button {
      font-size: 1.6rem;
    }

    @include tablet-portrait {
      justify-content: center;
    }
  }

  .form-bottom {
    p {
      font-size: 1.4rem;
      margin-bottom: 2rem;
    }
  }

  .thank-you {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 2rem;
  }

  @include mobile {
    .form-row {
      flex-direction: column;
      gap: 0;
    }
  }
}
