.section {
  position: relative;
  padding: 10rem 0;
  font-size: 1.6rem;

  h2 {
    font-size: 24px;
    font-weight: 800;
    line-height: 28px;
    width: 100%;
    margin-bottom: 2rem;
    color: var(--fenhuma-blue);

  }

  h3 {
    margin-bottom: 2.4rem;

    @include mobile(){
      sup{
        top: 8px;
        position: relative;
      }
    }

  }

  p {
    font-size: 1.6rem;
    font-weight: 400;
    line-height: 2.4rem;
    margin-bottom: 1rem;
    opacity: 0.8;

    &.bold {
      font-weight: 700;
    }

    a {
      text-decoration: underline;

      &.btn{
        text-decoration: none;
      }

      &.external-link:not(.btn){
        color: var(--fenhuma-pink);
      }
    }


  }

  ul {
    margin-bottom: 1.5rem;
    li {
      font-size: 1.6rem;
      a {
        text-decoration: underline;
      }
    }
  }

  .white-box {
    background-color: var(--fenhuma-white);
    box-shadow: 8px 8px 32px rgba(0, 72, 135, 0.04);
    border-radius: 24px;
    padding: 6rem;
    @include mobile(){
      padding: 2rem;
    }
  }

  &--grey {
    background-color: var(--bunov-grey);
  }

  &--beige {
    background-color: var(--bunov-beige);
  }

  &--white {
    background-color: var(--bunov-white);
  }


  &.breakthrough-pain-section{

    background-image: url("../img/breakthrough-pain.svg");
    background-repeat: no-repeat;
    background-position: calc(50% - -650px) 100px;

    .breakthrough-pain{

      @include mobile(){
        background: none;

        p:last-of-type{
          margin-bottom: 0;
        }
      }
    }

  }




  &.update-patient-pain-management{

    background-image: url("../img/pain-management-extended.svg");
    background-repeat: no-repeat;
    background-position: calc(50% - -600px) 100px;


    overflow-x: hidden;

    @include mobile(){
      padding-bottom: 0;
    }

  }


  &.hcp-review {
    .green-background {
      img {
        object-fit: cover;
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
      }
    }
  }

  &--half-half {
    .inner-container,
    .container {
      display: flex;
      gap: 5rem;
      position: relative;

      @include phablet {
        flex-direction: column;
        gap: 5rem;

        &.mobile-reverse {
          flex-direction: column-reverse;
        }
      }

      .left,
      .right {
        flex: 1;

        &.align-end {
          position: relative;
          display: flex;
          justify-content: flex-end;
        }

        &.align-start {
          position: relative;
          display: flex;
          justify-content: flex-end;
        }
      }

      .left {
        display: flex;
        flex-direction: column;
        gap: 1.6rem;
        align-items: flex-start;

        @include tablet-portrait {
          //align-items: center;
        }

        .block {
          width: 426px;
          background-color: var(--bunov-white);
          font-size: 1.8rem;
          font-weight: 700;
          line-height: 2.2rem;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 2.2rem 0;
          flex-direction: column;
          text-align: center;
          position: relative;

          &--large {
            padding: 2.2rem 10rem;

            img {
              max-width: 100%;
              margin-top: 2rem;
            }
          }

          .icon {
            position: absolute;
            width: 4rem;
            height: 4rem;
            background-color: #eedccb;
            border: 1px solid var(--bunov-orange);
            border-radius: 50%;
            bottom: -2.6rem;
            z-index: 5;
            box-shadow: 0px 0px 0px 0.7rem var(--bunov-grey);
            display: flex;
            justify-content: center;
            align-items: center;

            img {
              display: block;
              max-width: 40%;
            }
          }
        }
      }

      .single-image {
        display: flex;
        justify-content: flex-end;
        align-items: center;

        img {
          box-shadow: 0px 4px 24px rgba(24, 34, 16, 0.2);
          border-radius: 8px;
        }
      }
    }

    &.left-middle {
      .left {
        justify-content: center;
      }
    }

    &-green-overlap {
      .inner-container {
        gap: 10rem;
      }
      .left {
        position: relative;
        z-index: 2;
        color: var(--bunov-white);
        justify-content: center;

        p,
        img {
          max-width: 36rem;
        }
      }
      position: relative;
      .green-overlap {
        position: absolute;
        top: -3.5rem;
        left: -2.5rem;
        width: 53%;
        height: 96%;
        background-color: var(--bunov-green);
      }
    }
  }

  &.increase-padding {
    padding-bottom: 17rem;

    @include mobile {
      padding-bottom: 5rem;
    }
  }

  &--full-width {
    text-align: center;

    p {
      margin: 0;
    }

    &--left {
      text-align: left;

      p {
        max-width: 870px;
        margin-bottom: 1rem;

        &:last-of-type {
          margin-bottom: 2.4rem;
        }
      }

      ul {
        li {
          opacity: 0.8;
        }
      }
    }
  }

  &--prescribe {
    h2 {
      @include mobile {
        max-width: 100%;
      }
    }

    .inner-container {
      gap: 3rem;
      .left {
        flex: initial;
        align-items: center;
        justify-content: flex-start;
        img {
          width: 460px;
          min-width: 460px;

          @include mobile(){
            min-width: auto;
            width: 100%;
          }

        }
      }
    }
  }

  &--contraindications {

    .grid.grid-2-cols{
      column-gap: 2rem;
      row-gap: 1.5rem;
      grid-auto-rows: 1fr;
    }

    h2 {
      margin-bottom: 1rem;
    }

    p {
      margin-bottom: 3rem;
    }

    ul {
      padding: 0;
      text-align: left;
      display: grid;
      column-gap: 4.5rem;
      row-gap: 2rem;
      grid-template-columns: repeat(auto-fill, minmax(10rem, 41rem));
      justify-content: center;
      list-style: none;

      li {
        position: relative;
        padding-left: 2rem;
        &:before {
          position: absolute;
          left: 0;
          content: "";
          width: 1rem;
          height: 1rem;
          border-radius: 50%;
          border: 1px solid rgba(0, 0, 0, 0.2);
          top: 0.7rem;
        }
      }
    }

    .grid{
      margin-top: 40px;

      & > div{
        background-color: #F3F3F3;
        border-radius: 16px;
        padding: 2.5rem;
        text-align: left;
        display: flex;
        align-items: center;
      }
    }
  }

  &--considerations {

    background-image: url("../img/breakthrough-pain.svg");
    background-repeat: no-repeat;
    background-position: calc(50% - -650px) 100px;


    a{
      color: var(--fenhuma-pink);
      text-decoration: underline;
    }
  }

  &--bottom-boxes{

    .grid.grid-2-cols{
      column-gap: 2rem;
      grid-auto-rows: 1fr;
    }

    .cta{
      background-color: var(--fenhuma-blue);
      border-radius: 16px;
      color: #fff;
      padding: 4rem;
      display: flex;
      flex-direction: column;

      p{
        flex: 1;
      }

      h4{
        margin-bottom: 20px;
        font-size: 18px;
      }

      .btn{
        margin-top: 20px;
      }
    }
  }

  &.admin-of-fenhuma{

    .grid.grid-2-cols{
      column-gap: 5rem;
    }

    .row{
      display: flex;
      flex-direction: row;

      .icon{

        width: 48px;
        height: 48px;

        img{

        }
      }

      .text{
        padding: 0 1rem 1rem;
      }

    }
  }

  &.titrate-patient{

    img{
      padding-bottom: 2.5rem;
    }

    .table-wrapper{
      max-width: 100%;
      .mobile-scroller-msg{
        display: none;
      }


      @include mobile(){
        overflow-x: scroll;

        .mobile-scroller-msg{
          display: block;
          font-style: italic;
          margin-bottom: 3rem;
        }

      }

    }

    table{

      padding-top: 2.5rem;
      padding-bottom: 2.5rem;

      tr{
        td:first-child{
          background-color: var(--fenhuma-blue);
          color: #fff;
          font-weight: 700;

          @include mobile(){
            min-width: 510px;
          }

        }

        td{
          background-color: #fff;
          padding: 1.5rem;
          height: 75px;

        }
      }

      tr:first-of-type{
        td:first-of-type{
          border-top-left-radius: 16px;
        }

        td:last-of-type{
          border-top-right-radius: 16px;
        }

      }

      tr:nth-of-type(2){
        td:first-of-type{
          border-bottom-left-radius: 16px;
        }

        td:last-of-type{
          border-bottom-right-radius: 16px;
        }
      }

    }

  }

  &.steps-area{

    .white-box{
      padding: 32px;
      margin-bottom: 25px;

      @include mobile(){
        padding: 15px;
      }

    }

    a{
      color: var(--fenhuma-pink);
    }

    .step{
      color: var(--fenhuma-pink);
      font-weight: 700;
      text-transform: uppercase;
      font-size: 12px;
      display: block;
      margin-bottom: 10px;
    }
  }

  &.discuss-sides{

    background-image: url("../img/discuss-sides-extended.svg");
    background-repeat: no-repeat;
    background-position: calc(50% - 550px) 100px;



    .inner-container{

      @include mobile(){
        position: relative;
      }

    }

    .left{
      justify-content: center;

      @include mobile(){

          position: relative;
          z-index: 1;
      }

      .btn{
        img{
          width: 16px;
          vertical-align: middle;

          @include mobile(){
            width: 17px;
          }

        }
      }

    }

    .blue-box{
      background-color: var(--fenhuma-blue);
      border-radius: 16px;
      padding: 5rem;
      text-align: center;
      color: #fff;


      @include mobile(){
        padding: 3rem;
      }


      p{
        opacity: 1;

        strong{
          font-size: 18px;
        }
      }

      a.btn{
        margin-top: 2rem;
      }
    }

    .right a {
      color: var(--fenhuma-pink);
    }
  }



  &--steps {
    .step-block-container {
      justify-content: center;
      width: 100%;
      margin: 3.2rem 0;

      display: grid;
      gap: 0.8rem;
      grid-template-columns: repeat(auto-fill, minmax(10rem, 20rem));

      @include tablet-portrait {
        grid-template-columns: 20rem;
      }

      .step-block {
        height: 19rem;
        background-color: var(--bunov-white);
        display: flex;
        flex-direction: column;

        .letter {
          flex: 1;
          display: flex;
          justify-content: center;
          align-items: center;

          .letter-inner {
            width: 6rem;
            height: 6rem;
            background: rgba(62, 85, 41, 0.1);
            color: var(--bunov-green);
            font-size: 3.2rem;

            border: 1px solid var(--bunov-green);
            border-radius: 50%;
            justify-content: center;
            align-items: center;
            display: flex;
          }
        }

        p {
          flex: 1;
          font-size: 1.6rem;
          line-height: 2.2rem;
        }
      }

      & ~ p.bold br {
        display: none;
      }
    }
  }

  &--references {
    font-size: 1.4rem;
    line-height: 2rem;
    padding: 5rem 0;

    &.with-divider {
      border-top: 1px solid var(--bunov-grey);
    }

    h6 {
      font-size: 1.6rem;
      font-weight: 700;
      margin-bottom: 1.5rem;
    }

    ol {
      padding-left: 1.3rem;

      li {
        a {
          line-break: anywhere;
          text-decoration: underline;
        }
      }

      & ~ p {
        font-size: 1.4rem;
        margin-top: 2rem;
      }
    }
  }

  &--abbreviations {
    padding-top: 6rem;
    padding-bottom: 0;

    p {
      margin-bottom: 0;
    }
  }

  &--resources {
    h2 {
      margin-bottom: 2.5rem;
    }

    .resource {
      &:last-of-type {
        border-bottom: 0;
        margin-bottom: 0;
        padding-bottom: 0;
      }
    }
  }

  .green-block {
    width: 100%;
    background-color: var(--bunov-green);
    color: var(--bunov-white);
    padding: 8rem 0;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2.4rem;
    position: relative;

    svg {
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
    }

    p,
    a {
      position: relative;
      z-index: 2;
    }
  }

  .tall-block-container {
    display: flex;
    gap: 2.5rem;

    .tall-block {
      background-color: var(--bunov-white);
      flex: 1;
      padding: 4rem 3rem;
      text-align: left;
      .icon {
        margin-bottom: 1.6rem;
      }

      p {
        margin-bottom: 1.5rem;

        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }
  }

  @include phablet {
    padding: 6rem 0;

    h2 {
      font-size: 2.4rem;
      line-height: 2.8rem;
    }

    &--half-half {
      .inner-container {
        .left {
          .block {
            width: 100%;
          }
        }

        .right {
          &.align-end {
            justify-content: flex-start;
          }
        }
      }
    }

    &--considerations {

      .inner-container {
        flex-direction: column-reverse;
      }
    }

    &--abbreviations {
      padding-bottom: 0;
    }

    .tall-block-container {
      flex-direction: column;
    }
  }

  &-active-manage {
    @include tablet-portrait {
      padding: 0;
      .green-overlap {
        display: none;
      }

      .inner-container {
        padding: 0;
        gap: 3rem;

        .left {
          background-color: var(--bunov-green);
          padding: 0 var(--container-padding);
          padding-bottom: 2rem;
          padding-top: 4rem;
        }

        .right {
          padding: 0 var(--container-padding);
          padding-bottom: 2rem;
        }
      }
    }
  }
}

.product-list {
  display: flex;
  justify-content: center;
  gap: 0.9rem;
  margin: 3.2rem 0;

  img {
    max-width: 24rem;
  }

  @include mobile {
    flex-direction: column;
    align-items: center;

    img {
      max-width: 68%;
    }
  }
}

.product-graph {
  max-width: 45.3rem;

  @include mobile {
    max-width: 100%;
  }
}

.couple-image {
  width: 35.4rem;
  height: 35.4rem;
  position: relative;
  z-index: 2;

  &.man {
    border-radius: 8px;
  }

  @include mobile {
    width: 34.4rem;
    height: 34.4rem;
    margin-bottom: 5rem;
  }
}

.green-background {
  position: absolute;
  background-color: var(--bunov-green);
  top: -17rem;
  height: calc(100% + 17rem + 10rem);
  z-index: 0;
  display: flex;
  justify-content: flex-end;
  overflow: hidden;

  img {
    max-width: 42rem;

    object-fit: contain;

    &.mobile-circles {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }

  &--left {
    left: 0;
    top: -3.5rem;
    height: 100%;
  }

  @include phablet {
    height: calc(100% + 5rem);
    top: -5rem;

    &--left {
      // display: none;

      & ~ .inner-container {
        gap: 5rem;
      }

      width: 50%;

      top: 0;

      svg {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
      }
    }
  }
}

.section-active-manage {
  .inner-container .left {
    margin-top: -6.3rem;

    @include tablet-portrait {
      margin-top: 0;
    }
  }
}
